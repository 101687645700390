<template>
	<div>
		<ts-page-title :title="$t('user.pageTitle')" :breadcrumb="[
			{ text: $t('home'), href: '/' },
			{
				text: $t('user.pageTitle'),
				active: true
			}
		]" />
		<ts-panel>
			<ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
				<div class="tw-space-x-2">
					<Button type="info" ghost @click="$router.push({ name: 'user-create' })">{{ $t('addNew') }}
					</Button>
					<Button type="success" @click.prevent="exportExcel" :loading="exporting">
						<i class="fas fa-file-excel"></i> {{ $t('export') }}
					</Button>
				</div>
				<div class="tw-justify-end tw-space-x-2">
					<Input search v-model="search" :placeholder="$t('user.search')" :style="width"
						@on-focus="() => (width = 'width: 500px')" @on-blur="() => (width = 'width: 300px')" />
					<ts-button v-tooltip="$t('filter')" @click.prevent="visible = true">
						<i class="fa fa-filter"></i>
					</ts-button>
				</div>
			</ts-panel-wrapper>
			<Table :columns="columns" :data="resources" size="small" :loading="loading" max-height="700" stripe>
				<template slot-scope="{ row }" slot="branch">
					{{
						row.employee.branch
						? row.employee.branch.branch_name_en
						: ''
					}}
				</template>
				<template slot-scope="{ row }" slot="employee">
					{{ row.employee ? row.employee.employee_name_en : '' }}
				</template>
				<template slot-scope="{ row }" slot="position">
					{{
						row.employee
						? row.employee.logical_position
							? row.employee.logical_position.position_name_en
							: ''
						: ''
					}}
				</template>
				<template slot-scope="{ row }" slot="roles">
					<span class="badge bg-primary tw-mr-1 tw-capitalize" v-for="(role, index) in row.roles" :key="index">{{
						role.name }}</span>
				</template>
				<template slot-scope="{ row }" slot="defaul_branch">
					<span class="badge bg-blue tw-mr-1 tw-capitalize">{{
						defaulBranch(row.branches)
					}}</span>
				</template>
				<template slot-scope="{ row }" slot="disable">
					<Icon v-if="!row.disable" type="ios-checkmark-circle-outline text-success" size="20" />
					<Icon v-else type="ios-close-circle-outline text-danger" size="20" />
				</template>
				<template slot-scope="{ row }" slot="action">
					<a href="#" class="text-primary ml-2" @click.prevent="onEdit(row)" v-tooltip="$t('edit')">
						<Icon type="ios-create" size="20" />
					</a>
					<a href="#" class="text-warning ml-2" @click.prevent="showModal(row)" v-tooltip="$t('user.resetPassword')">
						<Icon type="md-key" size="20" />
					</a>
				</template>
			</Table>
			<div class="tw-flex tw-items-center tw-justify-end tw-p-4">
				<ts-i-pagination v-model="pagination" @navigate="fetchData"></ts-i-pagination>
			</div>
		</ts-panel>
		<Drawer :title="$t('filter')" :closable="false" :value="visible" @on-close="() => (visible = false)" width="300px">
			<div class="mb-3">
				<label class="form-col-label control-label">{{
					$t('branchName')
				}}</label>
				<ts-branch-filter @filter="value => (branch_id = value)" />
			</div>
		</Drawer>
		<Modal v-model="visible_resetpwd" width="360">
			<p slot="header" style="color:#f60;text-align:center">
				<Icon type="ios-information-circle"></Icon>
				<span>{{ $t('user.resetPassword') }}</span>
			</p>
			<div class="mb-3">
				<label class="required">{{ $t('user.newPassword') }}</label>
				<input v-model="model.new_password" type="password" class="form-control" :class="{
					'is-invalid': errors.has('new_password')
				}" />
				<div class="invalid-feedback" v-if="errors.has('new_password')">
					{{ errors.first('new_password') }}
				</div>
			</div>
			<div class="mb-3">
				<label class="required">{{ $t('user.confirm') }}</label>
				<input v-model="model.password_confirmation" type="password" class="form-control" :class="{
					'is-invalid': errors.has('password_confirmation')
				}" />
				<div class="invalid-feedback" v-if="errors.has('password_confirmation')">
					{{ errors.first('password_confirmation') }}
				</div>
			</div>
			<div>
				<ts-checkbox v-model="model.is_revoked">{{
					$t('user.terminateAllOtherSessoins')
				}}</ts-checkbox>
			</div>
			<div slot="footer">
				<Button type="warning" size="large" long :loading="waiting" @click="onReset">{{ $t('reset') }}
				</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { Errors } from 'form-backend-validation'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'

export default {
	name: 'userIndex',
	data() {
		return {
			loading: false,
			width: 'width: 300px',
			visible_resetpwd: false,
			waiting: false,
			exporting: false,
			errors: new Errors(),
			visible: false,
			branch_id: [],
			model: {
				user_id: 0,
				is_revoked: true,
				new_password: null,
				password_confirmation: null
			}
		}
	},
	computed: {
		...mapState('auth/user', ['resources', 'pagination']),
		search: {
			get() {
				return this.$store.state.auth.user.search
			},
			set(newValue) {
				this.$store.commit('auth/user/SET_SEARCH', newValue)
				this.$store.commit('auth/user/RESET_CURRENT_PAGE')
			}
		},
		columns() {
			return [
				{
					title: this.$t('user.userName'),
					key: 'user_name',
					minWidth: 200,
					sortable: true
				},
				{
					title: this.$t('user.branch'),
					slot: 'branch',
					minWidth: 100,
					sortable: true
				},
				{
					title: this.$t('user.employeeName'),
					key: 'employee',
					slot: 'employee',
					minWidth: 200,
					sortable: true
				},
				{
					title: this.$t('user.position'),
					key: 'position',
					slot: 'position',
					minWidth: 250,
					sortable: true
				},
				{
					title: this.$t('user.roleName'),
					key: 'roles',
					slot: 'roles',
					minWidth: 250
				},
				{
					title: this.$t('user.defaultBranch'),
					key: 'defaul_branch',
					slot: 'defaul_branch',
					minWidth: 250,
					align: 'center'
				},
				{
					title: this.$t('user.active'),
					key: 'disable',
					align: 'center',
					slot: 'disable',
					minWidth: 90,
					filters: [
						{
							label: 'Enable',
							value: false
						},
						{
							label: 'Disable',
							value: true
						}
					],
					filterMultiple: false,
					filterRemote(value) {
						this.fetchData({
							filter: {
								disable: value
							}
						})
					}
				},
				{
					title: this.$t('user.createdDate'),
					key: 'created_at',
					align: 'center',
					minWidth: 150,
					sortable: true
				},
				{
					title: this.$t('user.updatedDate'),
					key: 'updated_at',
					align: 'center',
					minWidth: 150,
					sortable: true
				},
				{
					title: this.$t('actions'),
					key: 'action',
					slot: 'action',
					fixed: 'right',
					align: 'center',
					width: 90
				}
			]
		}
	},
	methods: {
		fetchData(attributes) {
			this.loading = true
			this.$store
				.dispatch('auth/user/fetch', {
					...attributes,
					branch_id: this.branch_id
				})
				.catch(error => {
					this.loading = false
					this.notice({ type: 'error', text: error.message })
				})
				.finally(() => {
					this.loading = false
				})
		},
		defaulBranch(branches) {
			return branches.find(b => b.pivot.is_default == true)
				? branches.find(b => b.pivot.is_default == true).branch_name_en
				: ''
		},
		onEdit(record) {
			this.$store.commit('auth/user/SET_EDIT_DATA', record)
			this.$router.push({
				name: 'user-edit',
				params: { id: record.user_id }
			})
		},
		showModal(record) {
			this.errors = new Errors()
			this.model.user_id = record.user_id
			this.visible_resetpwd = true
		},
		onReset() {
			this.waiting = true
			this.errors = new Errors()
			this.$store
				.dispatch('auth/user/passwordReset', {
					id: this.model.user_id,
					data: this.model
				})
				.then(response => {
					this.visible_resetpwd = false
					this.notice({ type: 'success', text: response.message })
					this.model.new_password = null
					this.model.password_confirmation = null
				})
				.catch(error => {
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => {
					this.waiting = false
				})
		},
		exportExcel() {
			this.errors = new Errors()
			this.exporting = true
			this.$store
				.dispatch(
					'auth/user/exportExcel')
				.then(response => {
					let filename = trim(
						response.headers['content-disposition']
							.substring(
								response.headers['content-disposition'].indexOf(
									'filename'
								)
							)
							.replace('filename=', ''),
						'"'
					)
					FileDownload(response.data, filename)
				})
				.catch(err => {
					let error = JSON.parse(
						String.fromCharCode.apply(null, new Uint8Array(err))
					)
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => (this.exporting = false))
		},
		notice(not) {
			this.$Notice[not.type]({
				title: 'USER',
				desc: not.text
			})
		}
	},
	watch: {
		search: debounce(function (search) {
			this.fetchData({ search, branch_id: this.branch_id })
		}, 500),
		branch_id: debounce(function (value) {
			this.fetchData({ branch_id: value })
		}, 500)
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fetchData()
		})
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('auth/user/RESET_STATE')
		next()
	}
}
</script>
<style scoped>
.text-nowrap {
	white-space: nowrap;
}
</style>
